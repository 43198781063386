<template>
  <a-row>
    <a-col :span="24" class="d-flex flex-column justify-content-center align-items-center">
      <a-icon :type="'loading'" style="font-size: 10rem; color: #0559CB" />
    </a-col>

    <a-col :span="24" class="d-flex flex-column justify-content-center align-items-center">
      <div class="mt-5 mb-3 pbz-font subtitle-md-medium">
        Proses Cek Stok
      </div>
      <div class="text-center pbz-font body-md-regular" style="color: #999999">
        Harap menunggu sistem kami sedang melakukan pengecekan pada inventori
      </div>
    </a-col>
  </a-row>
</template>

<script>
export default {
  name: 'LoadingCheckStockInvenotry',
}
</script>

<style>

</style>
