<template>
  <a-row>
    <a-col :span="24" class="d-flex flex-column justify-content-center align-items-center">
      <div class="mt-5 mb-3 pbz-font subtitle-md-medium">
        Data Not Found
      </div>
      <div class="text-center pbz-font body-md-regular" style="color: #999999">
        Call IT Admin.
      </div>
    </a-col>
  </a-row>
</template>

<script>
export default {
  name: 'NotFoundCheckStockInvenotry',
}
</script>

<style>

</style>
